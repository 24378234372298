import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Header from "../components/Header";
import { useAuth } from "../context/auth";

const Confirmation = (props) => {
  const [loading, setLoading] = useState(true);

  const auth = useAuth();
  const { authError } = auth;
  console.log("authError", authError);
  const [params] = useSearchParams();
  const id = params.get("id");
  const email = params.get("email");
  console.log("id", id);
  console.log("email", email);
  let counter = 1;

  useEffect(() => {
    console.log("counter", counter);
    auth.validateRegistrationToken({ id, email });
    setLoading(false);
    counter++;
  }, []);

  return (
    <>
      <Header />
      <section className="bg-grey-50 dark:bg-grey-900 py-6 lg:py-12 transition-colors duration-50 ease-linear">
        <div className="mx-auto max-w-xl px-4">
          <div className="card w-full max-w-lg">
            <div className="mb-6 flex justify-center">
              <img
                className="h-8 lg:h-10"
                src="../assets/images/logo/logo-herdpin.svg"
                alt="HerdPin"
              />
            </div>
            <div className="mb-6 text-center">
              {loading ? (
                <div></div>
              ) : authError === null ? (
                <>
                  <h3 className="mb-1 text-2xl font-bold text-grey-900 dark:text-grey-100 md:text-3xl">
                    Registration Success
                  </h3>
                  <p className="text-base font-medium text-grey-400 dark:text-grey-300 md:text-lg">
                    Congratulations registration successful.
                  </p>
                  <Link
                    to="/"
                    className="mt-6 lg:mt-12 inline-block w-full rounded-lg bg-blue-500 px-4 py-2.5 text-center text-sm font-semibold text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-100 focus:dark:ring-grey-800 md:py-3 md:text-base"
                  >
                    Click here to continue
                  </Link>
                </>
              ) : (
                <>
                  <h3 className="mb-1 text-2xl font-bold text-grey-900 dark:text-grey-100 md:text-3xl">
                    Something went wrong...
                  </h3>
                  <p className="text-base font-medium text-grey-400 dark:text-grey-300 md:text-lg capitalize">
                    {authError}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Confirmation;
