import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../helper/email";

import IconClose from "./icons/close";
import { validatePassword } from "../helper/password";
import { useAuth } from "../context/auth";

const Signup = ({
  name,
  email,
  password,
  callback,
  showClose = true,
  showLogo = true,
  accountCreated = false,
  showPassword = true,
  onAlreadyHaveAccountClick,
  isModal = false,
}) => {
  const [userName, setUserName] = useState(name === undefined ? "" : name);
  const [userEmail, setUserEmail] = useState(email === undefined ? "" : email);
  const [userPassword, setUserPassword] = useState(
    password === undefined ? "" : password
  );
  const [userAccountCreated, setUserAccountCreated] = useState(accountCreated);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();
  const auth = useAuth();
  const { saveUser, signupError, resetAuthNotifications } = auth;

  const emptyName = userName.length > 0 ? false : true;
  const emptyEmail = userEmail.length > 0 ? false : true;
  const emptyPassword = userPassword.length > 0 ? false : true;

  console.log("isModal", isModal);

  useEffect(() => {
    resetAuthNotifications();
  }, []);

  const validateForm = () => {
    const formErrors = [];
    if (!validateEmail(userEmail)) {
      formErrors.push("Please enter a valid email address");
    }

    if (showPassword && !validatePassword(userPassword)) {
      formErrors.push(
        "Password needs to be 8 characters long, include at least 1 number, 1 uppercase, 1 lowercase character and 1 special character e.g. ? or !"
      );
    }

    if (userName.length < 1) {
      formErrors.push("Please enter your name");
    }

    if (formErrors.length > 0) {
      setErrors(formErrors);
      return false;
    }

    return true;
  };

  const createUser = async () => {
    const response = await saveUser({
      name: userName,
      email: userEmail,
      password: showPassword ? userPassword : null,
    });
    console.log("response", response);
    if (response) {
      setUserAccountCreated(true);
      if (callback !== null) {
        callback();
      }
    }
  };

  const renderSuccess = () => {
    return (
      <div>
        <p className="text-sm font-medium text-grey-400 dark:text-grey-300 md:text-base">
          {showPassword
            ? "Check your email and click the link in there to complete registration."
            : "Account created."}
        </p>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <>
        <form action="" className="mb-2 md:mb-4">
          {errors.length > 0 || signupError !== null ? (
            <div className="mb-4 rounded-lg border border-red-500 bg-red-100 p-4 md:mb-8">
              <ul className="list-disc pl-4">
                {errors.length > 0 &&
                  errors.map((e, idx) => {
                    return (
                      <li
                        className="text-sm font-medium text-red-500"
                        key={idx}
                      >
                        {e}
                      </li>
                    );
                  })}
                {signupError !== null && (
                  <li
                    className="text-sm font-medium text-red-500"
                    key="signupError"
                  >
                    Email address already in use. Please{" "}
                    <Link
                      to="/signin"
                      title="Login"
                      style={{ textDecoration: "underline" }}
                    >
                      Login
                    </Link>{" "}
                    or use the{" "}
                    <Link
                      to="/forgotten-password"
                      title="Forgot Password"
                      style={{ textDecoration: "underline" }}
                    >
                      Forgot Password
                    </Link>{" "}
                    feature.
                  </li>
                )}
              </ul>
            </div>
          ) : null}
          <div className="mb-4 md:mb-8">
            <label
              className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="name"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder="Your name"
              aria-label="name"
              aria-invalid={
                emptyName ? null : userName.length > 0 ? false : true
              }
              className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
            />
          </div>
          <div className="mb-4 md:mb-8">
            <label
              className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              placeholder="Your email"
              aria-label="email"
              aria-invalid={
                emptyEmail ? null : validateEmail(userEmail) ? false : true
              }
              className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
            />
          </div>

          {showPassword && (
            <div className="mb-4 md:mb-8">
              <label
                className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
                placeholder="Your password"
                aria-label="password"
                aria-invalid={
                  emptyPassword ? null : userPassword.length > 0 ? false : true
                }
                className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
              />
            </div>
          )}

          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setErrors("");
                const formOk = validateForm();
                if (formOk) {
                  createUser();
                }
              }}
              className="inline-block w-full rounded-lg bg-blue-500 px-4 py-2.5 text-center text-sm font-semibold text-white transition-colors hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-100 md:py-3 md:text-base"
            >
              Continue
            </button>
          </div>
        </form>
        <div className="text-sm text-grey-400 dark:text-grey-300 md:text-base text-center">
          Already have an account?{" "}
          {isModal ? (
            <button
              onClick={onAlreadyHaveAccountClick}
              className="text-blue-500"
            >
              Click here
            </button>
          ) : (
            <button
              onClick={() => navigate("/signin")}
              className="text-blue-500"
            >
              Click here
            </button>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="relative card w-full max-w-lg">
        {showClose && (
          <div className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center">
            <a
              href="#close"
              aria-label="Close"
              data-target="modal-signup"
              onClick={() => callback()}
            >
              <IconClose />
            </a>
          </div>
        )}
        <div className="mb-6 flex justify-center">
          {showLogo && (
            <img
              className="h-8 lg:h-10"
              src="../assets/images/logo/logo-herdpin.svg"
              alt="HerdPin"
            />
          )}
        </div>
        <div className="mb-6 text-center">
          <h3 className="mb-1 text-2xl font-bold text-grey-900 dark:text-grey-100 md:text-3xl">
            Join our community
          </h3>
          <p className="text-base font-medium text-grey-400 dark:text-grey-300 md:text-lg">
            Create a free account with your email.
          </p>
        </div>
        {userAccountCreated ? renderSuccess() : renderForm()}
      </div>
    </>
  );
};

export default Signup;
