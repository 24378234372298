import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import { validateEmail } from "../helper/email";
import IconClose from "./icons/close";

const Login = ({
  email,
  accountCreated,
  callback,
  showClose = true,
  showLogo = true,
  onForgotPasswordClick,
  onAlreadyHaveAccountClick,
  isModal = false,
}) => {
  const [password, setPassword] = useState("");
  const [userEmail, setUserEmail] = useState(email === undefined ? "" : email);
  const [,] = useState(accountCreated);

  const navigate = useNavigate();
  const auth = useAuth();
  const { authError, user, resetAuthNotifications } = auth;

  const emptyEmail = userEmail.length > 0 ? false : true;

  useEffect(() => {
    resetAuthNotifications();
  }, []);

  const login = () => {
    auth
      .login({
        email: userEmail,
        password,
      })
      .then((response) => {
        console.log("response in component", response);
        if (response) {
          console.log("response in if", response);
          // Redirect
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("Login error: ", error);
      });
  };

  return (
    <>
      <div className="relative card w-full max-w-lg">
        {showClose && (
          <div className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center">
            <a
              href="#close"
              aria-label="Close"
              data-target="modal-signup"
              onClick={() => callback()}
            >
              <IconClose />
            </a>
          </div>
        )}
        <div className="mb-6 flex justify-center">
          {showLogo && (
            <img
              className="h-8 lg:h-10"
              src="../assets/images/logo/logo-herdpin.svg"
              alt="HerdPin"
            />
          )}
        </div>
        <div className="mb-6 text-center">
          <h3 className="mb-1 text-2xl font-bold text-grey-900 dark:text-grey-100 md:text-3xl">
            Existing user?
          </h3>
          <p className="text-base font-medium text-grey-400 dark:text-grey-300 md:text-lg">
            Log in below with your account details.
          </p>
        </div>
        {authError !== null && (
           <div className="mb-4 rounded-lg border border-red-500 bg-red-100 p-4 md:mb-8">
           <ul className="list-disc pl-4">
             <li className="text-sm font-medium text-red-500" key="1">{authError}</li>
           </ul>
         </div>
        )}
        <form action="" className="mb-2 md:mb-4">
          <div className="mb-4 md:mb-8">
            <label
              className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              placeholder="Your email"
              aria-label="email"
              aria-invalid={
                emptyEmail ? null : validateEmail(userEmail) ? false : true
              }
              className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
            />
          </div>
          <div className="mb-4 md:mb-8">
            <label
              className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              aria-label="password"
              className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
            />
            <div className="text-sm text-grey-400 dark:text-grey-300 md:text-base text-center mt-2">
              Forgot your password?{" "}
              {isModal ? (
                <button
                  onClick={onForgotPasswordClick}
                  className="text-blue-500"
                >
                  Click here
                </button>
              ) : (
                <button
                  onClick={() => navigate("/forgotten-password")}
                  className="text-blue-500"
                >
                  Click here
                </button>
              )}
            </div>
          </div>
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                login();
              }}
              className="inline-block w-full rounded-lg bg-blue-500 px-4 py-2.5 text-center text-sm font-semibold text-white transition-colors hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-100 md:py-3 md:text-base"
            >
              Continue
            </button>
          </div>
        </form>
        <div className="text-sm text-grey-400 dark:text-grey-300 md:text-base text-center">
          Don't have an account?{" "}
          {isModal ? (
            <button
              onClick={onAlreadyHaveAccountClick}
              className="text-blue-500"
            >
              Click here
            </button>
          ) : (
            <button
              onClick={() => navigate("/signup")}
              className="text-blue-500"
            >
              Click here
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
