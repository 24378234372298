import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createEvent, getUserEvents } from "../actions/event";
// import { saveUser } from "../actions/user";
import { validateEmail } from "../helper/email";
import Header from "../components/Header";
import { MAX_DAYS_TO_SHOW } from "../constants";

import { useAuth } from "../context/auth";

const defaultUser = {
  name: "",
  email: "",
  accountCreated: false,
};

// TODO May need to flesh out some better error handling if the localStorage is corrupt for whatever reason. 
// EG set user to undefined.
export const loadUser = () => {
  const user = localStorage.getItem("user");
  return user === null ? defaultUser : JSON.parse(user);
};

function App() {

  const navigate = useNavigate();

  const auth = useAuth();
  let {user, saveUser, signupError, resetAuthNotifications} = auth;
  console.log(user);
  
  if (user === null || user === undefined) {
    user = defaultUser;
  }

  // Default Start and End Days
  const start = new Date();
  const end = new Date();
  end.setDate(start.getDate() + MAX_DAYS_TO_SHOW);

  const [pollErrors, setPollErrors] = useState([]);
  console.log(pollErrors);

  const [userName, setUserName] = useState(user.name);
  const [userEmail, setUserEmail] = useState(user.email);
  const [accountCreated] = useState(user.accountCreated);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(start.toISOString().split('T')[0]); // Formats the date to YYYY-MM-DD
  const [endDate, setEndDate] = useState(end.toISOString().split('T')[0]);       // Formats the date to YYYY-MM-DD
  const [restrictedDaysOfWeek, setRestrictedDaysOfWeek] = useState(["0","1","2","3","4","5","6"]);

  const [showAdvanced, setShowAdvanced] = useState(false);

  const emptyTitle = title.length > 0 ? false : true;
  const emptyName = userName !== undefined && userName.length > 0 ? false : true;
  const emptyEmail = userEmail !== undefined && userEmail.length > 0 ? false : true;

  useEffect(() => {
    // Reset Auth Notifications
    resetAuthNotifications();
  }, [])

  const onRestrictedDaysOfWeekChange = (e) => {
    if (!e.target.checked) {
      const idx = restrictedDaysOfWeek.indexOf(e.target.value);
      restrictedDaysOfWeek.splice(idx, 1);
      setRestrictedDaysOfWeek([...restrictedDaysOfWeek]);
    } else {
      setRestrictedDaysOfWeek([...restrictedDaysOfWeek, e.target.value]);
    }
  };

  const createEventAction = async (newEvent) => {
    console.log(newEvent);
    let allOk = true;
    // See if there is a user to create
    if (newEvent.hasOwnProperty("name") && newEvent.hasOwnProperty("email")) {
      const { name, email } = newEvent;
      const user = { name, email, accountCreated: true };
      const userCreated = await saveUser(user);
      if(!userCreated) {
        allOk = false;
      }
      delete newEvent["name"];
      delete newEvent["email"];
    }
  
    if(allOk) {
      const { name, email } = user;
      const event = { ...newEvent, owner: { name, email } };
      const response = await createEvent(event);
      console.log(response);
    
      if (response !== undefined) {
        const {PublicHash} = response;
        navigate(`/event/${PublicHash}`);
      } else {
        alert("Error creating event");
        return false;
      }
    }
  }

  const validate = () => {
      const formErrors = [];
      if (
        !accountCreated &&
        userName.length === 0
      ) {
        formErrors.push("Enter your name");
      }
      if (
        !accountCreated &&
        userEmail.length === 0
      ) {
        formErrors.push("Enter your email");
      }
      if (title.length < 1) {
        formErrors.push("Give your poll a title");
      }

      if (startDate !== "") {
        const start = new Date(startDate);
        start.setHours(0,0,0,0);
        const now = new Date();
        now.setHours(0,0,0,0);
        if (
          now.getTime() > start.getTime()
        ) {
          formErrors.push(
            "Start date must not be in the past"
          );
        }
      }

      if (endDate !== "") {
        const end = new Date(endDate);
        const now = new Date();
        if (now.getTime() > end.getTime()) {
          formErrors.push(
            "Select an end date in the future"
          );
        }
      }

      if (startDate !== "" && endDate !== "") {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (end < start) {
          formErrors.push("End date must be after the start date");
        }
      }

      // Check that if there is a start and end date then the poll will show some actual days if it is too restricted.
      if (
        restrictedDaysOfWeek.length > 0 &&
        (startDate !== "" || endDate !== "")
      ) {
        let hasDays = false;
        const daysToShow = MAX_DAYS_TO_SHOW;
        // Work out the difference in days
        const start = startDate !== "" ? new Date(startDate) : new Date();
        

        let end = null;
        if (endDate !== "") {
          end = new Date(endDate);
        } else {
          end = new Date();
          end.setDate(start.getDate() + daysToShow);
        }

        const difference = end.getTime() - start.getTime();
        const daysDifference = Math.ceil(difference / (1000 * 3600 * 24)) + 1;

        for (let i = 0; i < daysDifference; i++) {
          var date = new Date();
          date.setDate(start.getDate() + i);
          if (
            restrictedDaysOfWeek.indexOf(`${date.getDay()}`) > -1
          ) {
            hasDays = true;
            break;
          }
        }

        if (!hasDays) {
          formErrors.push(
            "Your poll won't have any days available. Try adjust the start/end date."
          );
        }
      }

      if (formErrors.length > 0) {
        setPollErrors(formErrors);
        // e.preventDefault();
        return false;
      }
      return true;
  }

  return (
    <>
      <Header name={user.name} />
      <section className="bg-grey-50 dark:bg-grey-900 py-6 lg:py-12 transition-colors duration-50 ease-linear">
        <div className="max-w-3xl mx-auto px-4">
          <div className="mx-auto max-w-xl text-center">
            <h1 className="text-3xl font-extrabold tracking-tight text-grey-900 dark:text-grey-100 md:text-5xl">
              Create a new date poll
            </h1>
          </div>
          <div className="mx-auto mt-2 max-w-xl text-center lg:mt-6 mb-6 lg:mb-12">
            <p className="text-md font-medium text-grey-400 dark:text-grey-300 md:text-xl">
              Herdpin quickly allows you schedule team meetings, group collaborations and events
              with friends.
            </p>
          </div>
          <div className="card">
            <div>
              <form onSubmit={(e) => {
                e.preventDefault();
                const valid = validate();
                console.log('valid', valid);
                if(valid) {
                  // TODO Set loading state for it.
                  const e = {
                    title,
                    description,
                    start_date: startDate,
                    end_date: endDate,
                    days: restrictedDaysOfWeek
                  };

                  if (!accountCreated) {
                    e['name'] = userName;
                    e['email'] = userEmail;
                  }
                  console.log(accountCreated);
                  console.log('event pre', e);
                  createEventAction(e);
                }
              }}>
                {!accountCreated ? (
                  <>
                    <div className="mb-4 md:mb-8">
                      <label
                        className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                        htmlFor="name"
                      >
                        Name*
                      </label>
                      <input
                        type="text"
                        maxLength={64}
                        name="name"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        placeholder="Your name"
                        aria-label="name"
                        aria-invalid={
                          emptyName ? null : userName.length > 0 ? false : true
                        }
                        className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
                      />
                    </div>
                    <div className="mb-4 md:mb-8">
                      <label
                        className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                        htmlFor="email"
                      >
                        Email*
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={userEmail}
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                        placeholder="Email address"
                        aria-label="email"
                        aria-invalid={
                          emptyEmail
                            ? null
                            : validateEmail(userEmail)
                            ? false
                            : true
                        }
                        className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
                      />
                    </div>
                  </>
                ) : null}
                <div className="mb-4 md:mb-8">
                  <label
                    className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                    htmlFor="title"
                  >
                    Poll title*
                  </label>
                  <input
                    type="text"
                    name="title"
                    maxLength={64}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Title of the group poll"
                    aria-label="title"
                    aria-invalid={
                      emptyTitle ? null : title.length > 0 ? false : true
                    }
                    className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
                  />
                </div>
                <div className="mb-4 md:mb-8">
                  <label
                    className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                    htmlFor="description"
                  >
                    Poll description
                  </label>
                  <textarea
                    value={description}
                    maxLength={256}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="A short description of the group poll (optional)"
                    aria-label="description"
                    className="block h-32 w-full resize-none rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
                    name="description"
                  />
                </div>
                <div className="mb-4 md:mb-8 flex items-center justify-between">
                  <button
                    id="advancedSettingsLink"
                    className="text-md font-bold tracking-tight text-blue-500 md:text-lg"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAdvanced(!showAdvanced);
                      return false;
                    }}
                  >
                    Advanced settings
                  </button>
                  <button
                    id="advancedSettings"
                    className={`rounded-full transition-colors duration-75 ease-linear p-1 w-14 h-7 relative ${
                      showAdvanced
                        ? "bg-blue-500"
                        : "bg-grey-100 dark:bg-grey-600"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAdvanced(!showAdvanced);
                      return false;
                    }}
                  >
                    <div
                      id="advancedSettingsToggle"
                      className={`absolute top-1 z-10 h-5 w-5 rounded-full bg-white dark:bg-grey-100 transition-all duration-150 ease-linear ${
                        showAdvanced ? "translate-x-7" : "translate-x-0"
                      }`}
                    ></div>
                  </button>
                </div>
                {showAdvanced && (
                  <div id="advancedSettings">
                    <div className="mb-4 md:mb-8">
                      <label
                        className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                        htmlFor="startDate"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="The start date for your poll"
                        aria-label="email"
                        className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
                        name="start_date"
                      />
                    </div>
                    <div className="mb-4 md:mb-8">
                      <label
                        className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                        htmlFor="end_date"
                      >
                        To:
                      </label>
                      <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="The end date for your poll"
                        aria-label="email"
                        className="w-full rounded-lg border border-grey-100 dark:border-grey-600 dark:bg-grey-800 px-4 py-2.5 text-sm font-normal text-grey-800 dark:text-grey-300 outline-none placeholder:text-grey-400 hover:border-grey-200 hover:dark:border-grey-500 focus:border-blue-500 focus:dark:border-grey-500 md:py-3 md:text-base"
                        name="end_date"
                      />
                    </div>
                    <div className="mb-4 md:mb-8">
                      <label
                        className="mb-1 block text-sm font-medium text-grey-900 dark:text-grey-300 md:mb-2 md:text-base"
                        htmlFor="days"
                      >
                        Days:
                      </label>
                      <div className="space-y-1">
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekMonday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="1"
                              id="dayOfWeekMonday"
                              checked={
                                restrictedDaysOfWeek.indexOf("1") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Monday</div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekTuesday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="2"
                              id="dayOfWeekTuesday"
                              checked={
                                restrictedDaysOfWeek.indexOf("2") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Tuesday</div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekWednesday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="3"
                              id="dayOfWeekWednesday"
                              checked={
                                restrictedDaysOfWeek.indexOf("3") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Wednesday</div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekThursday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="4"
                              id="dayOfWeekThursday"
                              checked={
                                restrictedDaysOfWeek.indexOf("4") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Thursday</div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekFriday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="5"
                              id="dayOfWeekFriday"
                              checked={
                                restrictedDaysOfWeek.indexOf("5") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Friday</div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekSaturday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="6"
                              id="dayOfWeekSaturday"
                              checked={
                                restrictedDaysOfWeek.indexOf("6") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Saturday</div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="poll-setting"
                            htmlFor="dayOfWeekSunday"
                          >
                            <input
                              type="checkbox"
                              name="days"
                              value="0"
                              id="dayOfWeekSunday"
                              checked={
                                restrictedDaysOfWeek.indexOf("0") > -1
                                  ? true
                                  : false
                              }
                              onChange={onRestrictedDaysOfWeekChange}
                            />
                            <div className="checkbox">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <div>Sunday</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {pollErrors.length > 0 || signupError !== null ? (
                  <>
                    <div className="mb-4 rounded-lg border border-red-500 bg-red-100 p-4 md:mb-8">
                      <ul className="list-disc pl-4">
                        {pollErrors.map((e, idx) => {
                          return (
                            <li
                              className="text-sm font-medium text-red-500"
                              key={idx}
                            >
                              {e}
                            </li>
                          );
                        })}
                        {signupError !== null && (
                          <li
                            className="text-sm font-medium text-red-500"
                            key="signupError"
                          >
                            Email address already in use. Please <Link to="/signin" title="Login" style={{textDecoration: "underline"}}>Login</Link> or use the <Link to="/forgotten-password" title="Forgot Password" style={{textDecoration: "underline"}}>Forgot Password</Link> feature.
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                ) : null}
                <div>
                  <button
                    className="inline-block w-full rounded-lg bg-blue-500 px-4 py-2.5 text-center text-sm font-semibold text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-100 focus:dark:ring-grey-800 md:py-3 md:text-base"
                    type="submit"
                  >
                    Create group poll
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
