import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDarkMode from "../helper/useDarkMode";
import IconMoon from "./icons/moon";
import IconSun from "./icons/sun";
import { useAuth } from "../context/auth";
import IconMenu from "./icons/menu";
import IconClose from "./icons/close";

const shortenName = (name) => {
  if(name === undefined) {
    return "";
  }
  const parts = name.split(" ");
  return parts[0];
}

const Menu = ({ user, logout, navigate, toggleDarkMode, darkMode }) => (
  <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-8 mt-4 lg:mt-0">
    <div className="flex flex-col lg:flex-row items-start lg:items-center gap-y-2 lg:gap-x-8 text-sm font-medium text-grey-400 dark:text-grey-300 md:text-base">
      {user === null || user === undefined ? (
        `Hey!`
      ) : (
        <>
          Hey, {shortenName(user.name)}!<Link to="/dashboard">My Account</Link>
          <a
            href="#logout"
            onClick={(e) => {
              e.preventDefault();
              logout();
              navigate("/loggedout");
            }}
          >
            Logout
          </a>
        </>
      )}
    </div>
    {user === null && (
      <div className="flex flex-col lg:flex-row items-start lg:items-center gap-y-2 lg:gap-x-8">
        <Link to="/signup">
          <p className="text-sm font-medium text-grey-400 dark:text-grey-300 md:text-base">
            Sign Up
          </p>
        </Link>
        <Link to="/signin">
          <p className="text-sm font-medium text-grey-400 dark:text-grey-300 md:text-base">
            Sign In
          </p>
        </Link>
      </div>
    )}
    <button
      className="rounded-full duration-150 ease-linear p-1 w-14 h-7 relative bg-grey-100 dark:bg-grey-600"
      onClick={toggleDarkMode}
    >
      <div className="relative flex items-center justify-between gap-x-2">
        <IconSun />
        <IconMoon />
        <div
          className={`absolute z-10 h-5 w-5 rounded-full bg-white dark:bg-grey-100 transition-transform duration-150 ease-linear ${
            darkMode ? "translate-x-7" : "translate-x-0"
          }`}
        ></div>
      </div>
    </button>
  </div>
);

const Header = () => {
  const auth = useAuth();
  console.log(auth);
  const { user, logout } = auth;
  console.log('user', user);
  console.log('auth', auth);
  const navigate = useNavigate();

  const [colorTheme, setTheme] = useDarkMode();
  const [darkMode, setDarkMode] = useState(
    colorTheme === "light" ? true : false
  );
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDarkMode = () => {
    setTheme(colorTheme);
    setDarkMode(!darkMode);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="border-b border-grey-100 dark:border-grey-600 bg-white dark:bg-grey-800 p-4 transition-colors duration-50 ease-linear">
      <div className="flex w-full items-center justify-between">
        <div>
          <Link to="/">
            <img
              className="h-8 lg:h-10"
              src={`../assets/images/logo/logo-herdpin${
                darkMode ? "-white.svg" : ".svg"
              }`}
              alt="HerdPin"
            />
          </Link>
        </div>
        <div className="flex items-center space-x-4 lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-grey-900 dark:text-grey-300"
          >
            {menuOpen ? <IconClose /> : <IconMenu />}
          </button>
        </div>
        <div className="hidden lg:flex">
          <Menu
            user={user}
            logout={logout}
            navigate={navigate}
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
          />
        </div>
      </div>
      {menuOpen && (
        <div className="lg:hidden">
          <Menu
            user={user}
            logout={logout}
            navigate={navigate}
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
          />
        </div>
      )}
    </header>
  );
};

export default Header;
