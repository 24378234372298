import { createContext, useContext, useState } from "react";
import { API_URL } from "../constants";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [user, setUser] = useState(localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : null); // NOTE Perhaps add a default user with a state of logged out.
  const [token, setToken] = useState(localStorage.getItem('site') || "");
  const [authError, setAuthError] = useState(null);
  const [signupError, setSignupError] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [magicLinkSuccess, setMagicLinkSuccess] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  const login = async (loginUser) => {
    setAuthError(null);
    const { email, password } = loginUser;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Email: email,
        Password: password,
      }),
      credentials: "include",
    };

    const response = await fetch(`${API_URL}/v1/user/login`, requestOptions);

    if (response.status === 200) {
      const u = await response.json();
      setUser({...u, accountCreated: true});
      localStorage.setItem('user', JSON.stringify({...u, accountCreated: true}));
    } else if (response.status === 500){
      setAuthError('Something went wrong on our server :(');
    } else if(response.status === 404){
      setAuthError('Oops looks like our serer went away :(');
    } else {
      setAuthError('An error occurred :(');
    }
  };

  const logout = () => {
    setUser(null); // NOTE Perhaps set to a default user.
    setToken("");
    resetAuthNotifications();

    localStorage.removeItem('site');
    localStorage.removeItem('user'); // NOTE Maybe could leave this
    return true;
  };

  const magicLink = async (payload) => {
    setAuthError(null);
    setMagicLinkSuccess(false);
    const { email, redirectTo } = payload;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Email: email,
        RedirectTo: redirectTo,
      }),
      credentials: "include",
    };

    const response = await fetch(
      `${API_URL}/v1/user/magiclink`,
      requestOptions
    );

    if (response.status === 201) {
      setMagicLinkSuccess(true);
      return true;
    } else if (response.status === 500){
      setAuthError('Something went wrong on our server :(');
    } else if(response.status === 404){
      setAuthError('Oops looks like our serer went away :(');
    } else {
      setAuthError('An error occurred :(');
    }
    return false;
  };

  const saveUser = async (user) => {
    const {name, email, password} = user;
    const payload = {
      Name: name,
      Email: email,
    };

    let requestUrl = `${API_URL}/v1/user/`;

    // If there is a password we make it a proper registration.
    if (password !== undefined && password !== null) {
      payload["Password"] = password;
      requestUrl = `${API_URL}/v1/user/registration`;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
      credentials: "include",
    };

    const response = await fetch(requestUrl, requestOptions);

    if (response.status === 201){ // 201 for created.
      const user = await response.json();
      setSignupSuccess(true);
      if (password === undefined || password === null) {
        setUser({...user, accountCreated: true}); // Only set the user if its a quick registration and not one with a password
        localStorage.setItem("user", JSON.stringify({...user, accountCreated: true}));
      }
      // Dont set the user just yet as they havent validated their account. doing this here will "log them in" with no token
      return true;
    } else if (response.status === 404) {
      setSignupError("Oops looks like our server went away :(");
    } else if (response.status === 409) {
      setSignupError("Email address already in use.");
    } else if (response.status === 500) {
      setSignupError("Something went wrong on our server :(");
    } else {
      setSignupError("An error occurred :(");
    }

    return false;
  };

  const validateRegistrationToken = async (user) => {
    const { email, id } = user;
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    const response = await fetch(`${API_URL}/v1/user/registration/confirmation?id=${id}&email=${email}`, requestOptions)
    const {status} = response;

    if (status === 200) {
      const u = await response.json();
      setUser({...u, accountCreated: true});
      localStorage.setItem('user', JSON.stringify({...u, accountCreated: true}));
      // Don't redirect here let the next action handle that
    } else if(status === 400) {
      setAuthError('id and email are required to validate a user');
    } else if(status === 404) {
      setAuthError('user not found');
    } else if(status === 409) {
      setAuthError('user already registered');
    } else if(status === 500) {
      setAuthError('oops there was a problem with the server');
    } else {
      setAuthError("an error occurred trying to validate your token");
    }
  };

  const validateMagicLink = async (user) => {
    const { email, id } = user;
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };
    const response = await fetch(
      `${API_URL}/v1/user/magiclink/confirmation?id=${id}&email=${email}`,
      requestOptions
    );
    const { status } = response;
    if (status === 200) {
      const u = await response.json();
      const {redirect_to} = u;
      setUser({...u, accountCreated: true});
      localStorage.setItem('user', JSON.stringify({...u, accountCreated: true}));
      // Set the redirect to
      setRedirectTo(redirect_to)
    } else if(status === 400) {
      setAuthError('id and email are required to validate a user');
    } else if(status === 404) {
      setAuthError('user not found');
    } else if(status === 409) {
      setAuthError('user already registered');
    } else if(status === 500) {
      setAuthError('oops there was a problem with the server');
    } else {
      setAuthError("an error occurred trying to validate your token");
    }
  };

  const resetAuthNotifications = () => {
    setMagicLinkSuccess(false);
    setSignupSuccess(false);
    setAuthError(null);
    setSignupError(null);
    setRedirectTo(null);
  }

  return <AuthContext.Provider 
  value={{
    user,
    token,
    authError,
    signupError,
    signupSuccess,
    magicLinkSuccess,
    redirectTo,
    login,
    logout,
    magicLink,
    saveUser,
    validateRegistrationToken,
    validateMagicLink,
    resetAuthNotifications
  }}
  >
    {children}
  </AuthContext.Provider>
}

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
