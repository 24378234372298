import Header from "../components/Header";
import MagicLink from "../components/MagicLink";

const ForgottenPasswordPage = (props) => {
  console.log(props);
  return (
    <>
        <Header />
        <section className="bg-grey-50 dark:bg-grey-900 py-6 lg:py-12 transition-colors duration-50 ease-linear">
          <div className="mx-auto max-w-xl px-4">
            <MagicLink showClose={false} showLogo={false} />
          </div>
        </section>
    </>
  );
};

export default ForgottenPasswordPage;