import { useEffect, useState } from 'react';
import { useSearchParams, Link } from "react-router-dom";
import Header from "../components/Header";
import { useAuth } from "../context/auth";

const LoggedOut = (props) => {

  const [loading, setLoading] = useState(true);
  
  const auth = useAuth();
  const {authError, redirectTo} = auth;
  console.log('authError', authError);
  const [params,] = useSearchParams();
  const id = params.get("id");
  const email = params.get("email");
  console.log('id', id);
  console.log('email', email);

  useEffect(() => {
    const user = auth.validateMagicLink({id, email})
    const {redirect_to} = user;
    if (redirect_to !== "") {
      // TODO REDIRECT
    }
    setLoading(false);
  }, [])


  return (
    <>
      <Header />
      <section className="bg-grey-50 dark:bg-grey-900 py-6 lg:py-12 transition-colors duration-50 ease-linear">
        <div className="mx-auto max-w-xl px-4">
          <div className="card w-full max-w-lg">
            <div className="mb-6 text-center">
              <h3 className="mb-1 text-2xl font-bold text-grey-900 dark:text-grey-100 md:text-3xl">You have been successfully logged out.</h3>
              <p className="text-base mt-4 font-medium text-grey-400 dark:text-grey-300 md:text-lg">
                To log back in click <Link to="/signin" title="Log back in" style={{textDecoration: "underline"}}>here.</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoggedOut;